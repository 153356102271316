import React, { useState, useEffect } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  ListItem,
  // OrderedList,
  UnorderedList,
  Button,
} from '@chakra-ui/react'
import { graphql } from 'gatsby'
import BaseLayout from '../../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../../components/ContentWithStyles/TransformToChakra'
import SEO from '../../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [word, setWord] = useState('Palabra')
  const [secondsLeft, setSecondsLeft] = useState(10)
  const selectRandomItem = someList =>
    someList[Math.floor(Math.random() * someList.length)]

  const [countdown, setCountdown] = useState()

  const startCountdown = duration => {
    let timeLeft = duration
    return setInterval(() => {
      timeLeft -= 1
      // console.log(`Tiempo restante: ${timeLeft}`)
      if (timeLeft < 0) {
        timeLeft = duration
        setWord(
          selectRandomItem(
            data.wordsJson.spanish.nouns
              .concat(data.wordsJson.spanish.adjectives)
              .concat(data.wordsJson.spanish.verbs)
          )
        )
      }
      setSecondsLeft(timeLeft)
    }, 1000)
  }

  const resetCountdown = () => {
    setWord(
      selectRandomItem(
        data.wordsJson.spanish.nouns
          .concat(data.wordsJson.spanish.adjectives)
          .concat(data.wordsJson.spanish.verbs)
      )
    )
    clearInterval(countdown)
    setSecondsLeft(10)
    setCountdown(startCountdown(10))
  }

  useEffect(() => {
    resetCountdown()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de palabras cada 10 segundos al azar"
        titleSeo="Generador de palabras cada 10 segundos al azar"
        description="Herramienta para generar palabras aleatorias cada diez segundos."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        breadcrumbs={[{ name: 'Palabras', url: '/palabras/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {secondsLeft}
            </Text>{' '}
            {word}
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de palabras al azar cada 10 segundos
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => resetCountdown()}
            >
              Reiniciar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>Generador de palabras cada 10 segundos al azar</Heading1>
        <Paragraph>
          &iquest;Necesitas obtener palabras en espa&ntilde;ol de forma
          aleatoria? &iquest;Quieres una aplicaci&oacute;n que genere una nueva
          palabra al azar cada 10 segundos? Si la respuesta a esas preguntas en
          s&iacute;, entonces aqu&iacute; tienes la utilidad que necesitas: un
          generador de palabras aleatorias cada 10 segundos, que una vez lo
          inicies ir&aacute; generando nuevas palabras al azar cada vez que
          pasen diez segundos, sin necesidad de intervenci&oacute;n ni control
          manual.
        </Paragraph>
        <Paragraph>
          No solo tendr&aacute;s la lista de palabras previamente recopilada y
          la selecci&oacute;n al azar automatizada, sino que adem&aacute;s
          contar&aacute;s con un sistema que de forma autom&aacute;tica te
          brindar&aacute; un nuevo t&eacute;rmino cada diez segundos.
        </Paragraph>
        <Paragraph>
          Sin importar qu&eacute; uso tengas en mente, esta herramienta de
          generaci&oacute;n de palabras aleatorias online con un temporizador
          incluido te proveer&aacute; exactamente, y &uacute;nicamente, de una
          funci&oacute;n. Su prop&oacute;sito tan acotado como simple y claro,
          por lo que si te encuentras en esta p&aacute;gina es posible que ya
          sepas para qu&eacute; quieres utilizarla y c&oacute;mo vas a emplear
          las palabras aleatorias obtenidas cada vez que pasen 10 segundos.
        </Paragraph>
        <Heading2>
          Un generador aleatorio simple pero efectivo y &uacute;til
        </Heading2>
        <Paragraph>
          Que no te enga&ntilde;en las apariencias, a pesar de ser una
          herramienta muy simple y sin demasiada (si es que tiene alguna)
          versatilidad, podr&aacute;s darles muchos usos y depender&aacute; de
          ti el poder sacar el m&aacute;ximo provecho posible de esta utilidad.
        </Paragraph>
        <Paragraph>Sus dos puntos fuertes son:</Paragraph>
        <UnorderedList>
          <ListItem>Lista de palabras extensa y verificada</ListItem>
          <ListItem>Automatizaci&oacute;n al alcance de cualquiera</ListItem>
        </UnorderedList>
        <Paragraph>
          En primer lugar, la lista de elementos desde la que parte para generar
          una nueva palabra cada 10 segundos es extensa y ha sido manualmente
          filtrada para asegurarse no solo de que todos los t&eacute;rminos son
          correctos, sino adem&aacute;s adecuados para todos los contextos y
          aptos para todos los p&uacute;blicos.
        </Paragraph>
        <Paragraph>
          No se debe subestimar la cantidad de tiempo y energ&iacute;a que
          requiere el recopilar miles de palabras distintas y variadas, para
          adem&aacute;s luego tener que controlarlas y filtrarlas. Todo este
          esfuerzo se puede ahorrar f&aacute;cilmente si en lugar de hacerlo de
          forma manual se recurre a un generador aleatorio online como lo es
          este, que ya cuenta con su propia base de datos de palabras aleatorias
          del idioma espa&ntilde;ol, provenientes de todos los contextos y de
          tipos muy variados.
        </Paragraph>
        <Paragraph>
          En segundo lugar, la automatizaci&oacute;n que provee es clave para
          obtener una palabra nueva cada diez segundos de forma c&oacute;moda y
          verdaderamente aleatoria. Uno no tiene que contar cada segundo para
          elegir una nueva palabra ni controlar un cron&oacute;metro,
          simplemente es dejar correr el generador de palabras y esperar a que
          pasen los diez segundos para obtener un nuevo resultado.
        </Paragraph>
        <Paragraph>
          El algoritmo de selecci&oacute;n aleatoria de nuevas palabras cada 10
          segundos es simple, pero de mucho provecho dado que no se corre el
          riesgo de estar siguiendo alg&uacute;n orden espec&iacute;fico, o
          alg&uacute;n patr&oacute;n en concreto, o dar preferencia a unas
          palabras por sobre otras, lo que resulta casi inevitable si un humano
          es el que realiza la elecci&oacute;n de nuevas palabras.
        </Paragraph>
        <Heading2>Palabras variadas a un ritmo fren&eacute;tico</Heading2>
        <Paragraph>
          Bueno, a decir verdad, quiz&aacute; intervalos de diez segundos no
          sean exactamente lo que uno normalmente describir&iacute;a como
          &ldquo;fren&eacute;tico&rdquo;, pero &iquest;no viene mal ponerle algo
          de sentimiento a una herramienta tan aburrida, &iquest;no?
        </Paragraph>
        <Paragraph>
          No tiene mucho m&aacute;s sentido explorar el c&oacute;mo funciona y
          qu&eacute; objetivo cumple una utilidad online tan simple y evidente
          como lo es este generador autom&aacute;tico de palabras, pero
          quiz&aacute; valga la pena adentrarnos un poco es sus posibles usos y
          en los tipos de palabras disponibles.
        </Paragraph>
        <Heading3>
          Para qu&eacute; utilizar un generador autom&aacute;tico de palabras
        </Heading3>
        <Paragraph>
          Su uso generalmente es popular en actividades grupales,
          desaf&iacute;os que involucren vocabulario o aleatoriedad y juegos de
          mesa que requieran de nuevos elementos con los que jugar cada 10
          segundos.
        </Paragraph>
        <Paragraph>
          Dado que el usuario no tiene control sobre qu&eacute; palabras se
          generar&aacute;n ni sobre las caracter&iacute;sticas de las mismas,
          por lo general el uso de este generador autom&aacute;tico es apto para
          las actividades y procesos que no dependan espec&iacute;ficamente de
          palabras con propiedades deseadas, sino que requieran principalmente
          de aleatoriedad y ritmo, que es justamente lo que esta herramienta de
          generaci&oacute;n de palabras cada diez segundos provee.
        </Paragraph>
        <Paragraph>
          Hay muchos casos de uso para una utilidad que asegura la
          elecci&oacute;n aleatoria de una nueva palabra en intervalos de diez
          segundos, y a pesar de ser muy simple no tiene por qu&eacute;
          limitarse a unos pocos ejemplos de uso, dado que justamente cumple una
          funci&oacute;n tan at&oacute;mica que puede ser incorporada a muchos
          procesos y actividades sin gran conflicto.
        </Paragraph>
        <Paragraph>
          Su simplicidad es precisamente lo que permite una incre&iacute;ble
          versatilidad en contextos en los que es posible utilizar un generador
          aleatorio de palabras, y a su vez lo que limite considerablemente
          qu&eacute; es lo que hace la herramienta, porque solo hace una cosa,
          pero dicha cosa es tan simple y acotada que puede ser incluida en
          muchas actividades distintas sin gran esfuerzo.
        </Paragraph>
        <Heading3>Qu&eacute; tipos de palabras nos podemos encontrar</Heading3>
        <Paragraph>
          Respecto al tipo de palabras que pueden ser generadas con esta
          herramienta, se trata de palabras comunes y conocidas, que son aptas
          para todos los p&uacute;blicos, y provienen de contextos muy
          distintos.
        </Paragraph>
        <Paragraph>
          Las palabras generadas cada diez segundos var&iacute;an en naturaleza,
          longitud, tipo, sonido, etc. y no siguen un patr&oacute;n
          predeterminado o predecible. Son palabras del idioma espa&ntilde;ol
          que no son excesivamente oscuras para facilitar su uso, pero s&iacute;
          son los suficientemente diferentes como para no producir una
          monoton&iacute;a en cuanto a los resultados obtenidos luego de muchos
          usos del generador.
        </Paragraph>
        <Paragraph>
          La herramienta <strong>no incluye</strong> sustantivos propios, es
          decir, denominaciones o nombres que hacen referencia a una entidad
          espec&iacute;fica: nombres de personas, lugares, eventos, etc. Si bien
          existen muchos sustantivos propios que son comunes entre
          hispanohablantes, &eacute;stos hacen referencia a una entidad, sujeto
          u objeto espec&iacute;fico y no suelen resultar &uacute;tiles para las
          actividades que hacen uso de palabras aleatorias.
        </Paragraph>
        <Paragraph>
          Las palabras que <strong>s&iacute; incluye</strong> son sustantivos
          comunes de todo tipo, adjetivos, verbos y, en general, palabras
          frecuentes del idioma espa&ntilde;ol que no requieren de un
          diccionario para ser entendidas y puestas en uso. Si tienes un proceso
          o actividad que requiere de palabras aleatorios en espa&ntilde;ol,
          seguramente esta herramienta te sea de utilidad porque las palabras
          generales ser&aacute;n adecuadas.
        </Paragraph>
        <Paragraph>
          La generaci&oacute;n no se puede personalizar, por lo que la
          aleatoriedad y variedad est&aacute;n aseguradas, pero
          &iquest;Qu&eacute; sucede si la palabra que se gener&oacute; no me
          gusta? Pues simplemente tocar&aacute; esperar unos 10 segundos hasta
          que aparezca la siguiente palabra. Siendo que la base de datos
          contiene miles de palabras, no hay por qu&eacute; temer a palabras que
          no nos sirvan para nuestra actividad en particular, porque es seguro
          que eventualmente se generar&aacute;n t&eacute;rminos que s&iacute;
          podremos utilizar sin dificultad alguna.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    wordsJson {
      spanish {
        nouns
        adjectives
        verbs
      }
    }
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
